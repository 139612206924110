import { Link } from '@remix-run/react';
import { Button } from '../ui/button';

export function StorageScholarsSection() {
  return (
    <section className="flex w-full items-center justify-center bg-primary/10 px-6 py-6 md:bg-transparent md:px-4 md:py-16">
      <div className="flex flex-col gap-8 rounded-md bg-white p-4 md:flex-row md:bg-transparent md:p-0">
        <div className="relative min-h-8 min-w-fit">
          <img
            src="/img/landing/storage-scholars/square.jpg"
            alt="Storage Scholars Integration"
            className="hidden size-64 rounded-2xl md:block lg:size-80"
          />
          <img
            src="/img/landing/storage-scholars/logo.png"
            alt="Storage Scholars Logo"
            className="-left-8 -top-8 md:-left-14 md:-top-3 absolute size-24 md:size-28"
          />
        </div>
        <div className="flex flex-col items-start justify-center">
          <p className="text-2xl md:font-semibold md:text-xl lg:text-2xl ">Moving made easy with</p>
          <p className="font-semibold text-2xl md:text-3xl lg:text-4xl">Storage Scholars</p>
          <div className="h-4 lg:h-8" />
          <p className="max-w-md lg:text-lg">
            We've partnered with Storage Scholars so student movers handle the heavy lifting for
            you.
          </p>
          <div className="h-6 lg:h-10" />
          <Button className="w-fit px-12 text-lg" asChild>
            <Link to="/storage-scholars">Learn more</Link>
          </Button>
        </div>
      </div>
    </section>
  );
}
